<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 개정 -->
          <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiValveRuptureId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <!-- 개정번호 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 개정일시 -->
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <!-- 개정자 -->
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-moc 
                      :editable="editable && isRevision"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="popupParam.psiValveRuptureId"
                      documentTitle="inMaterial"
                      mocRelatedTaskCd="RT00000001"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-4">
                    <!-- 개정사유 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 안전밸브 및 파열판 기본정보 -->
          <c-card title="안전밸브 및 파열판 기본정보" class="cardClassDetailForm">
            <template slot="card-select">
              <!-- 개정이력 -->
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiValveRuptureId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="psiValveRuptureId"
                name="selectedpsiValveRuptureId"
                label=""
                v-model="selectedpsiValveRuptureId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      <!-- (주) -->
                      {{ $language('(주)') }}
                    </div>
                    <div class="tooltipCustom">
                    <!-- ① 배출원인에는 안전밸브의 작동원인(냉각수 차단, 전기공급중단, 화재, 열팽창, 출구차단 등) 중 최대로 배출되는 원인을 기재합니다. -->
                    {{ $language('① 배출원인에는 안전밸브의 작동원인(냉각수 차단, 전기공급중단, 화재, 열팽창, 출구차단 등) 중 최대로 배출되는 원인을 기재합니다.') }}<br>
                    <!-- ② 형식에는 안전밸브의 형식(일반형, 벨루우즈형, 파일럿 조작형)을 기재합니다 -->
                    {{ $language('② 형식에는 안전밸브의 형식(일반형, 벨루우즈형, 파일럿 조작형)을 기재합니다') }}
                    </div>
                  </q-tooltip>
                </q-btn>
                <!-- 개정 -->
                <c-btn
                  v-show="(editable && popupParam.psiValveRuptureId && !isRevision) && isEnalbed"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <!-- 개정취소 -->
                <c-btn
                  v-show="(editable && popupParam.psiValveRuptureId && isRevision) && isEnalbed"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                      <c-btn
                  v-if="editable && popupParam.psiValveRuptureId && isEnalbed"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
                      <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <!-- 계기번호 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="계기번호"
                  name="valveRuptureNo"
                  v-model="data.valveRuptureNo">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 내용물 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="내용물"
                  name="inMaterial"
                  v-model="data.inMaterial">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <!-- 상태 -->
                <c-text
                  :editable="editable"
                  label="상태"
                  name="conditionVal"
                  v-model="data.conditionVal">
                </c-text>
              </div>
              <div class="col-3">
                <c-equip
                  :editable="editable"
                  label="설비"
                  name="equipmentCd"
                  v-model="data.equipmentCd">
                </c-equip>
              </div>
              <div class="col-3">
                <!-- 관련공정 -->
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <!-- 관리부서 -->
                <c-dept
                  :editable="editable"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 용량 및 노즐크기 -->
          <c-card title="용량 및 노즐크기" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-6">
                <!-- 배출용량 -->
                <c-text
                  suffix="kg/hr"
                  :editable="editable"
                  label="배출용량"
                  name="outVolume"
                  v-model="data.outVolume">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 정격용량 -->
                <c-text
                  suffix="kg/hr"
                  :editable="editable"
                  label="정격용량"
                  name="regulVolume"
                  v-model="data.regulVolume">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 노즐크기-입구 -->
                <c-text
                  :editable="editable"
                  label="노즐크기-입구"
                  name="nozzleSizeIn"
                  v-model="data.nozzleSizeIn">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 노즐크기-출구 -->
                <c-text
                  :editable="editable"
                  label="노즐크기-출구"
                  name="nozzleSizeOut"
                  v-model="data.nozzleSizeOut">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 보호기기 압력 -->
          <c-card title="보호기기 압력" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-6">
                <!-- 기기번호 -->
                <c-text
                  :editable="editable"
                  label="기기번호"
                  name="protectSerial"
                  v-model="data.protectSerial">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 운전(Mpa) -->
                <c-text
                  suffix="Mpa"
                  :editable="editable"
                  label="운전(Mpa)"
                  name="protectOperation"
                  v-model="data.protectOperation">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 설정(Mpa) -->
                <c-text
                  suffix="Mpa"
                  :editable="editable"
                  label="설정(Mpa)"
                  name="protectDesign"
                  v-model="data.protectDesign">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 안전밸브정보 -->
          <c-card title="안전밸브정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-6">
                <!-- 설정(Mpa) -->
                <c-text
                  suffix="Mpa"
                  :editable="editable"
                  label="설정(Mpa)"
                  name="valveSetting"
                  v-model="data.valveSetting">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 몸체재질 -->
                <c-text
                  :editable="editable"
                  label="몸체재질"
                  name="safetyValveMaterial"
                  v-model="data.safetyValveMaterial">
                </c-text>
              </div>
              <div class="col-6">
                <!-- Trim재질 -->
                <c-text
                  :editable="editable"
                  label="Trim재질"
                  name="safetyValveTrim"
                  v-model="data.safetyValveTrim">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="기타 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-6">
                <!-- 정밀도(오차범위) -->
                <c-text
                  :editable="editable"
                  label="정밀도(오차범위)"
                  name="precisionVal"
                  v-model="data.precisionVal">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 배출연결부위 -->
                <c-text
                  :editable="editable"
                  label="배출연결부위"
                  name="outConnect"
                  v-model="data.outConnect">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 배출원인 -->
                <c-text
                  :editable="editable"
                  label="배출원인"
                  name="outReason"
                  v-model="data.outReason">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 형식 -->
                <c-text
                  :editable="editable"
                  label="형식"
                  name="formVal"
                  v-model="data.formVal">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 20px !important;">
          <!-- 첨부파일 -->
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="첨부파일">
          </c-upload>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'valve-rupture-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiValveRuptureId: '',
        psiEquipmentCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PSI_SAFETYVALVERUPTUREDISK',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      saveUrl: transactionConfig.psi.pfi.valve.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      isEnalbed: true,
      selectedpsiValveRuptureId: null,
      data: {
        psiValveRuptureId: '',  // 안전밸브및파열판일련번호
        plantCd: '',  // 사업장코드
        valveRuptureNo: '',  // 계기번호
        equipmentCd: '',  // 설비코드
        inMaterial: '',  // 내용물
        conditionVal: '',  // 상태
        outVolume: '',  // 배출용량(kghr)
        regulVolume: '',  // 정격용량(kghr)
        nozzleSizeIn: '',  // 노즐크기-입구
        nozzleSizeOut: '',  // 노즐크기-출구
        protectSerial: '',  // 보호기기압력-기기번호
        protectOperation: '',  // 보호기기압력-운전(Mpa)
        protectDesign: '',  // 보호기기압력-설계(Mpa)
        valveSetting: '',  // 안전밸브-설정(Mpa)
        safetyValveMaterial: '',  // 안전밸브-몸체재질
        safetyValveTrim: '',  // 안전밸브-Trim재질
        precisionVal: '',  // 정밀도(오차범위)
        outConnect: '',  // 배출연결부위
        outReason: '',  // 배출원인
        formVal: '',  // 형식
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      saveCallData: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.popupParam.popeditable === undefined) {
        this.editable = this.$route.meta.editable;
        this.isEnalbed = true;
      } else if (this.popupParam.popeditable === true) {
        this.editable = this.$route.meta.editable;
        this.isEnalbed = true;
      } else {
        this.editable = false;
        this.isEnalbed = false;
      }
      // url setting
      this.detailUrl = selectConfig.psi.pfi.valve.get.url;
      this.insertUrl = transactionConfig.psi.pfi.valve.insert.url;
      this.updateUrl = transactionConfig.psi.pfi.valve.update.url;
      this.deleteUrl = transactionConfig.psi.pfi.valve.delete.url;
      this.revlistUrl = selectConfig.psi.pfi.valve.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiValveRuptureId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.psiValveRuptureId)
        this.getDetail(this.popupParam.psiValveRuptureId, true);
        this.selectedpsiValveRuptureId = this.popupParam.psiValveRuptureId
      } else {
        if (this.popupParam.psiEquipmentCd) {
          this.data.equipmentCd = this.popupParam.psiEquipmentCd;
        }
      }
    },
    getDetail(_psiValveRuptureId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiValveRuptureId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.regDtStr = this.data.regDtStr.slice(0, 19)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiValveRuptureId == this.popupParam.psiValveRuptureId && this.isEnalbed) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiValveRuptureId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';   
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: saveMsg,
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiValveRuptureId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            this.popupParam.psiValveRuptureId = null;
            this.getDetail(null, false);
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiValveRuptureId = result.data.psiValveRuptureId
      this.$set(this.attachInfo, 'taskKey', result.data.psiValveRuptureId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.psiValveRuptureId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.data.psiValveRuptureId) {
          this.selectedpsiValveRuptureId = row;
          this.getDetail(this.selectedpsiValveRuptureId, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedpsiValveRuptureId)
        }
      }
    },
  }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _vm.popupParam.psiValveRuptureId
                ? _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm revisionLayout",
                      attrs: { title: "개정", topClass: "topcolor-orange" },
                    },
                    [
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-1" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      required: _vm.editable && _vm.isRevision,
                                      editable: _vm.editable && _vm.isRevision,
                                      label: "개정번호",
                                      name: "revisionNum",
                                    },
                                    model: {
                                      value: _vm.data.revisionNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "revisionNum", $$v)
                                      },
                                      expression: "data.revisionNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-2" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: false,
                                      label: "개정일시",
                                      name: "regDtStr",
                                    },
                                    model: {
                                      value: _vm.data.regDtStr,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "regDtStr", $$v)
                                      },
                                      expression: "data.regDtStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-1" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: false,
                                      label: "개정자",
                                      name: "regUserName",
                                    },
                                    model: {
                                      value: _vm.data.regUserName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "regUserName", $$v)
                                      },
                                      expression: "data.regUserName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-moc", {
                                    attrs: {
                                      editable: _vm.editable && _vm.isRevision,
                                      isSubmit: _vm.saveCallData,
                                      document: _vm.data,
                                      documentId:
                                        _vm.popupParam.psiValveRuptureId,
                                      documentTitle: "inMaterial",
                                      mocRelatedTaskCd: "RT00000001",
                                      label: "MOC번호",
                                      name: "sopMocId",
                                    },
                                    on: {
                                      "update:document": function ($event) {
                                        _vm.data = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.data.sopMocId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "sopMocId", $$v)
                                      },
                                      expression: "data.sopMocId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      required: _vm.editable && _vm.isRevision,
                                      editable: _vm.editable && _vm.isRevision,
                                      label: "개정사유",
                                      name: "revisionContent",
                                    },
                                    model: {
                                      value: _vm.data.revisionContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "revisionContent",
                                          $$v
                                        )
                                      },
                                      expression: "data.revisionContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "안전밸브 및 파열판 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-select" },
                    [
                      _vm.popupParam.psiValveRuptureId
                        ? _c("c-select", {
                            attrs: {
                              editable: !_vm.isRevision,
                              comboItems: _vm.gridrev.data,
                              type: "custom",
                              typetext: "개정이력",
                              itemText: "revisionNum",
                              itemValue: "psiValveRuptureId",
                              name: "selectedpsiValveRuptureId",
                              label: "",
                            },
                            on: { input: _vm.rowClickRev },
                            model: {
                              value: _vm.selectedpsiValveRuptureId,
                              callback: function ($$v) {
                                _vm.selectedpsiValveRuptureId = $$v
                              },
                              expression: "selectedpsiValveRuptureId",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass: "custom-btn",
                              attrs: {
                                icon: "help",
                                color: "deep-purple-6",
                                "text-color": "white",
                                align: "center",
                                round: "",
                              },
                            },
                            [
                              _c(
                                "q-tooltip",
                                {
                                  attrs: {
                                    anchor: "bottom left",
                                    self: "top left",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tooltipCustomTop" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$language("(주)")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "tooltipCustom" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$language(
                                            "① 배출원인에는 안전밸브의 작동원인(냉각수 차단, 전기공급중단, 화재, 열팽창, 출구차단 등) 중 최대로 배출되는 원인을 기재합니다."
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$language(
                                            "② 형식에는 안전밸브의 형식(일반형, 벨루우즈형, 파일럿 조작형)을 기재합니다"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.popupParam.psiValveRuptureId &&
                                  !_vm.isRevision &&
                                  _vm.isEnalbed,
                                expression:
                                  "(editable && popupParam.psiValveRuptureId && !isRevision) && isEnalbed",
                              },
                            ],
                            attrs: { label: "개정", icon: "restart_alt" },
                            on: { btnClicked: _vm.SetRevision },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.popupParam.psiValveRuptureId &&
                                  _vm.isRevision &&
                                  _vm.isEnalbed,
                                expression:
                                  "(editable && popupParam.psiValveRuptureId && isRevision) && isEnalbed",
                              },
                            ],
                            attrs: { label: "개정취소", icon: "restart_alt" },
                            on: { btnClicked: _vm.CancelRevision },
                          }),
                          _vm.editable &&
                          _vm.popupParam.psiValveRuptureId &&
                          _vm.isEnalbed
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.deleteData },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "계기번호",
                            name: "valveRuptureNo",
                          },
                          model: {
                            value: _vm.data.valveRuptureNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "valveRuptureNo", $$v)
                            },
                            expression: "data.valveRuptureNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "내용물",
                            name: "inMaterial",
                          },
                          model: {
                            value: _vm.data.inMaterial,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "inMaterial", $$v)
                            },
                            expression: "data.inMaterial",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "상태",
                            name: "conditionVal",
                          },
                          model: {
                            value: _vm.data.conditionVal,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "conditionVal", $$v)
                            },
                            expression: "data.conditionVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-equip", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설비",
                            name: "equipmentCd",
                          },
                          model: {
                            value: _vm.data.equipmentCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentCd", $$v)
                            },
                            expression: "data.equipmentCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관련공정",
                            multiple: "single",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.data.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processCd", $$v)
                            },
                            expression: "data.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-dept", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관리부서",
                            name: "deptCd",
                          },
                          model: {
                            value: _vm.data.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deptCd", $$v)
                            },
                            expression: "data.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "용량 및 노즐크기" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "kg/hr",
                            editable: _vm.editable,
                            label: "배출용량",
                            name: "outVolume",
                          },
                          model: {
                            value: _vm.data.outVolume,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "outVolume", $$v)
                            },
                            expression: "data.outVolume",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "kg/hr",
                            editable: _vm.editable,
                            label: "정격용량",
                            name: "regulVolume",
                          },
                          model: {
                            value: _vm.data.regulVolume,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "regulVolume", $$v)
                            },
                            expression: "data.regulVolume",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "노즐크기-입구",
                            name: "nozzleSizeIn",
                          },
                          model: {
                            value: _vm.data.nozzleSizeIn,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "nozzleSizeIn", $$v)
                            },
                            expression: "data.nozzleSizeIn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "노즐크기-출구",
                            name: "nozzleSizeOut",
                          },
                          model: {
                            value: _vm.data.nozzleSizeOut,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "nozzleSizeOut", $$v)
                            },
                            expression: "data.nozzleSizeOut",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "보호기기 압력" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "기기번호",
                            name: "protectSerial",
                          },
                          model: {
                            value: _vm.data.protectSerial,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "protectSerial", $$v)
                            },
                            expression: "data.protectSerial",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "Mpa",
                            editable: _vm.editable,
                            label: "운전(Mpa)",
                            name: "protectOperation",
                          },
                          model: {
                            value: _vm.data.protectOperation,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "protectOperation", $$v)
                            },
                            expression: "data.protectOperation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "Mpa",
                            editable: _vm.editable,
                            label: "설정(Mpa)",
                            name: "protectDesign",
                          },
                          model: {
                            value: _vm.data.protectDesign,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "protectDesign", $$v)
                            },
                            expression: "data.protectDesign",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "안전밸브정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "Mpa",
                            editable: _vm.editable,
                            label: "설정(Mpa)",
                            name: "valveSetting",
                          },
                          model: {
                            value: _vm.data.valveSetting,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "valveSetting", $$v)
                            },
                            expression: "data.valveSetting",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "몸체재질",
                            name: "safetyValveMaterial",
                          },
                          model: {
                            value: _vm.data.safetyValveMaterial,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "safetyValveMaterial", $$v)
                            },
                            expression: "data.safetyValveMaterial",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "Trim재질",
                            name: "safetyValveTrim",
                          },
                          model: {
                            value: _vm.data.safetyValveTrim,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "safetyValveTrim", $$v)
                            },
                            expression: "data.safetyValveTrim",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기타 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "정밀도(오차범위)",
                            name: "precisionVal",
                          },
                          model: {
                            value: _vm.data.precisionVal,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "precisionVal", $$v)
                            },
                            expression: "data.precisionVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "배출연결부위",
                            name: "outConnect",
                          },
                          model: {
                            value: _vm.data.outConnect,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "outConnect", $$v)
                            },
                            expression: "data.outConnect",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "배출원인",
                            name: "outReason",
                          },
                          model: {
                            value: _vm.data.outReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "outReason", $$v)
                            },
                            expression: "data.outReason",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "형식",
                            name: "formVal",
                          },
                          model: {
                            value: _vm.data.formVal,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "formVal", $$v)
                            },
                            expression: "data.formVal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              staticStyle: { "padding-top": "20px !important" },
            },
            [
              _c("c-upload", {
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable,
                  label: "첨부파일",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }